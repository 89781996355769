import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function Component2()
{
    const backgroundImageUrl02 = './image02.jpg';
    const backgroundImageUrl04 = './image04.jpg';
    const backgroundImageUrl06 = './image06.jpg';
    const backgroundImageUrl07 = './image07.jpg';
    const backgroundImageUrl08 = './image16.jpg';
    var settings = {
      dots:true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
      autoplaySpeed: 1500, 
        
      };
    return(
        <>
          <Slider {...settings}>
          <div>
          <div className='bg-image-large-01 mt-5' style={{backgroundImage:`url(${backgroundImageUrl07})`}}></div>
          </div>
          <div>
          <div className='bg-image-large-01 mt-5' style={{backgroundImage:`url(${backgroundImageUrl02})`}}>
          </div>
          </div>
          <div>
          <div className='bg-image-large-01 mt-5' style={{backgroundImage:`url(${backgroundImageUrl08})`}}></div>
          </div>
          <div>
          <div className='bg-image-large-01 mt-5' style={{backgroundImage:`url(${backgroundImageUrl04})`}}></div>
          </div>
          <div>
          <div className='bg-image-large-01 mt-5' style={{backgroundImage:`url(${backgroundImageUrl06})`}}></div>
          </div>
         </Slider>
        </>
    )
}
export default Component2;